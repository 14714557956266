<template>
  <zingchart :data="myData" :series="items" v-if="processed"></zingchart>
</template>

<script>
import { mapActions } from "vuex";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
export default {
  name: "StudioReport",
  components: {
    zingchart: zingchartVue,
  },
  data() {
    return {
      processed: false,
      items: [],
      myData: {
        type: "hbar",
        globals: {
          fontFamily: "Roboto Mono"
        },
        backgroundColor:'none',
        title: {
          text: this.$t('reports.monthly_appointments'),
          "adjust-layout": true,
          color: "white"
        },
        "scale-x": {
          labels: [],
        },
        "scale-y": {
          guide: {
            "line-color": "var(--v-gris1-base)"
          }
        },
        plotarea:{
          adjustLayout: true
        },
        plot: {
          "bar-max-width": "12.5%",
          "border-radius-bottom-right": 10,
          "border-radius-top-right": 10,
        }
      },
    };
  },
  methods: {
    ...mapActions("reports", ["getStudiosReport"]),
    fetchStudioReport() {
      this.getStudiosReport({ options: this.options }).then((data) => {
        this.items.push({
          values: data.data.map((x) => {
            if (x.appointments[0]) {
              return x.appointments[0].total;
            }
            return null;
          }),
          'background-color': 'var(--v-primary-base)'
        });
        this.myData["scale-x"].labels = data.data.map(x=> x.studio_name);
        this.processed = true;
      });
    },
  },
  mounted() {
    this.fetchStudioReport();
  },
};
</script>